.bgMdi {
    background: linear-gradient(to right, #ea2227, #ea2227, #f88e1e);
}

.MDICover {
    background-image: url("../images/dashboard-bg.png");
}

.centerVerHor {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: white;
    border: yellowgreen 2px solid;
    padding-bottom: 5px;
}

.align-items-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}
